var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 ml-4",attrs:{"headers":_vm.headers,"items":_vm.organizations,"hide-default-footer":"","sort-by":"number"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{staticClass:"mt-8 mx-4",attrs:{"items":_vm.allDistricts,"item-text":"name","item-value":"id","dense":"","outlined":"","color":"grey","hint":"Filter by District","persistent-hint":"","solo":""},model:{value:(_vm.locationId),callback:function ($$v) {_vm.locationId=$$v},expression:"locationId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"mt-8 mx-4",attrs:{"dense":"","outlined":"","color":"grey","hint":"Filter by Name","persistent-hint":"","solo":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-btn',{staticClass:"mt-2",attrs:{"elevation":"1","fab":"","small":""},on:{"click":_vm.loadOrganizations}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"1200px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.showCreate)?_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"green","dark":""}},on),[_vm._v("Add Organization")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('validation-observer',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Organzition Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","outlined":"","name":"Organzition Name","error-messages":errors,"color":"grey","dense":"","solo":""},model:{value:(_vm.editedOrganization.name),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "name", $$v)},expression:"editedOrganization.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Organization Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.organizationtypes,"item-text":"name","item-value":"id","error-messages":errors,"dense":"","label":"Organization Type","outlined":"","color":"grey","solo":""},model:{value:(_vm.editedOrganization.typeId),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "typeId", $$v)},expression:"editedOrganization.typeId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Main Activity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Main Activity","outlined":"","name":"Main Activity","error-messages":errors,"color":"grey","dense":"","solo":""},model:{value:(_vm.editedOrganization.mainActivities),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "mainActivities", $$v)},expression:"editedOrganization.mainActivities"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"District"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.allDistricts,"item-text":"name","item-value":"id","dense":"","outlined":"","error-messages":errors,"color":"grey","label":"District Location","solo":""},model:{value:(_vm.editedOrganization.locationId),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "locationId", $$v)},expression:"editedOrganization.locationId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Address","outlined":"","name":"Address","error-messages":errors,"color":"grey","dense":"","solo":""},model:{value:(_vm.editedOrganization.address),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "address", $$v)},expression:"editedOrganization.address"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Contact Person Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Contact Person Name","outlined":"","name":"Contact Person Name","error-messages":errors,"color":"grey","dense":"","solo":""},model:{value:(_vm.editedOrganization.contactPerson),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "contactPerson", $$v)},expression:"editedOrganization.contactPerson"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Position"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Position","outlined":"","name":"Position","error-messages":errors,"color":"grey","dense":"","solo":""},model:{value:(_vm.editedOrganization.contactPosition),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "contactPosition", $$v)},expression:"editedOrganization.contactPosition"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required|digits:10","name":"Contact Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Contact Phone","outlined":"","name":"Contact Phone","error-messages":errors,"color":"grey","dense":"","solo":""},model:{value:(_vm.editedOrganization.contactPhone),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "contactPhone", $$v)},expression:"editedOrganization.contactPhone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Contact Email","outlined":"","name":"Contact Email","color":"grey","dense":"","solo":""},model:{value:(_vm.editedOrganization.contactEmail),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "contactEmail", $$v)},expression:"editedOrganization.contactEmail"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"success ml-10 mb-4",attrs:{"text":"","type":"submit"}},[_vm._v("Save")]),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-spacer')],1)],1)])],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"btn blue--text",on:{"click":function($event){return _vm.loadSingleOrganization(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editOrganization(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.showDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.organizationDialog(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"hide-overlay":"","max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{attrs:{"tile":"","outlined":""}},[_c('v-card-text',[_c('p',[_vm._v("Are you sure you want to delete this Organization ?")]),_c('v-btn',{attrs:{"link":"","small":"","color":"blue"},on:{"click":_vm.deleteOrganization}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"link":"","small":"","color":"blue"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("No")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }