
class Organization {
  constructor () {
    this.id = null
    this.name = null
    this.contactPerson = null
    this.typeId = null
    this.locationId = null
    this.contactPosition = null
    this.mainActivities = null
    this.contactPhone = null
    this.address = null
    this.contactEmail = null
  }

  toString () {
    return `Organization{id=${this.id}, name='${this.name}', contactPerson='${this.contactPerson}', typeId='${this.typeId}', locationId='${this.locationId}', contactPosition='${this.contactPosition}', intake='${this.intake}',
    mainActivities='${this.mainActivities}', contactPhone='${this.contactPhone}', address='${this.address}', contactEmail='${this.contactEmail}'}`
  }
}

export default Organization
